import React, {useEffect} from "react";
import useState from 'react-usestateref'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom';
import styles from './Form.module.scss';
import commonStyles from './Common.module.scss';
import AnimalsComponent from "./AnimalsComponent";
import { submitApplication, getApprovalCode } from '../utils/backendService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSpinner, faPlusSquare} from '@fortawesome/free-solid-svg-icons';
import { emitCustomEvent } from 'react-custom-events';
import useFormPersist from 'react-hook-form-persist'
import moment from 'moment';
import { useFieldArray } from "react-hook-form";

const PREMIUM_PCT = 0.069; // must match percent in backend function apply.js
const formDataProperty = 'form-data';

function Form2() {

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const getEmptyAnimal = function() {
    return {
      name: "",
      regno: "",
      gender: "",
      breed: "",
      use: "",
      lotno: "",
      purchaseprice: "",
      dob: moment().subtract(90, "days").format('MM/DD/YYYY'),
      creationTime : new Date().getTime(),
      itemState: "FIRST"
    };
  }

  const formDataObj = JSON.parse(localStorage.getItem(formDataProperty));
  const [acknowledged, toggleAck, acknowledgedRef] = useState(false)
  const [approvalCode, setApprovalCode, approvalCodeRef] = useState();
  const formProps = useForm({ defaultValues: {
    dateOfSale: window.sessionStorage.getItem("auctionDate"),
    animals: [getEmptyAnimal()]
  }});
  const { register, trigger, handleSubmit, setError, clearErrors, control, watch, setValue, getValues, formState: { isSubmitting,errors }} = formProps;
  const fieldArrayProps = useFieldArray({
    control,
    name: "animals"
  });
  const { fields, append, remove, update } = fieldArrayProps;

  const [submitted, updatedSubmitted, submittedRef] = useState(false);
  const [totalPurchasePrice, setTotalPurchasePrice, totalPurchasePriceRef] = useState();
  const [totalPremium, setTotalPremium, totalPremiumRef] = useState();
  const navigate = useNavigate();

   useFormPersist(formDataProperty, {watch, setValue}, {
     storage: window.localStorage,
   });

  async function fetchApprovalCode(){
    const APPROVAL_CODE = await getApprovalCode();
    setApprovalCode(APPROVAL_CODE.toLowerCase());
  }

  fetchApprovalCode();

  useEffect(() => {
    const setPriceAndPremium = (value) => {
      let purchasePrice = "$0";
      let premium = "$0";

      if (value.animals.length > 0){
        const currentAnimals = value.animals.filter(a => a.purchaseprice);
        if (currentAnimals.length > 0){
          const containsApprovalCode = currentAnimals.map(o => o.purchaseprice).some(x => (x !== null && x.toLowerCase() === approvalCode));
          if (containsApprovalCode){
            purchasePrice = "Verbal approval";
            premium = "TBD";
          } else {
            const totalPurchasePrice = currentAnimals.map(o => filterInt(o.purchaseprice)).reduce((a, c) => { return a + c });
            purchasePrice = "$" + totalPurchasePrice.toLocaleString("en-US");
            premium = "$" + numberWithCommas((totalPurchasePrice * PREMIUM_PCT).toFixed(2));
          }
        }
      }
      setTotalPurchasePrice(purchasePrice);
      setTotalPremium(premium);
    }
    if(approvalCode){
      setPriceAndPremium(getValues());
    }
    const subscription = watch((value, { name, type }) => {
      // Update all fields array of animals
      fields.forEach(function(part, index, theArray) {
        if(value.animals.length > index){
          update(index,Object.assign(theArray[index],value.animals[index]));
        }
      });
      setPriceAndPremium(value);
    });
    return () => subscription.unsubscribe();
  }, [watch, fields, update, approvalCode,setTotalPurchasePrice, setTotalPremium, getValues]);

  if (formDataObj === null) {
    console.log("No form data found, going home");
    return (
      <Navigate to='/' />
    );
  }

  async function onSubmit(data) {
    try {
      clearErrors("serverside");
      const formIsValid = await trigger();
      if(formIsValid) {
          const result = await submitApplication({
            ...data,
            totalPurchasePrice: totalPurchasePriceRef.current,
            totalPremium: totalPremiumRef.current,
          });
          console.log(result);
          updatedSubmitted(true);
      } else {
        console.log("Errors: ", errors);
      }
    } catch (error) {
      console.error("Submission Error: ", error);
      alert(error);
      //setError("serverside", { message: error.message || "Unknown error occurred" });
    }
  }

  function goHome() {
    updatedSubmitted(false);
    navigate("/");
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function filterInt(value) {
    if (/^([\d,]+)$/.test(value)) {
      return Number(value.replace(',', ''))
    } else {
      return NaN
    }
  }

  async function  handleAddCattle(){
    // Trigger validation on the form
    await trigger();
    emitCustomEvent('submit-open-form');

    if(fields.every(f => f.itemState === 'CLOSED')){
      const newAnimal = getEmptyAnimal();
      if(fields.length !== 0){
        newAnimal.itemState = "NEW";
      }
      append(newAnimal);
    } else {
        console.log("Please close all forms before adding a new one");
    }
  }

  if (submittedRef.current) {
    return <>
      <div className={styles.section}>
        <div className={styles.content} style={{minHeight:'500px'}}>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <h4>Thank you for your submission. We will get back to you soon.</h4>
            </div>
          </div>
          <div className={styles.buttonRow}>
            <input className={commonStyles.buttonClear} type="button" value="Go Home" onClick={() => goHome()}/>
          </div>
        </div>
      </div>
    </>;
  }

  return (
    <div className={styles.section}>
      <div className={styles.content}>
       <form onSubmit={handleSubmit(onSubmit)}>
        <h1>Tell us about your cattle</h1>
        <div className={`${styles.fieldRow} ${styles.topRow}`}>
          <div className={styles.field}>
            <div className={styles.label}>Name Of Sale</div>
            <input type="text" id="nameOfSale" placeholder="Enter sale name"  {...register(`nameOfSale`,
            {
              required: {value: true, message: "Sale Name is required"}
            })}/>
            { errors.nameOfSale && (
            <div className={styles.error}>{errors.nameOfSale.message}</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Date Of Sale</div>
            <select id="dateOfSale"  {...register(`dateOfSale`,
            {
              required: true
            })}>
              <option value={moment(yesterday).format('MM/DD/yyyy')} >{moment(yesterday).format('MMMM DD, YYYY')}</option>
              <option value={moment(today).format('MM/DD/yyyy')} >{moment(today).format('MMMM DD, YYYY')}</option>
            </select>
            {errors.dateOfSale && (
              <div className={styles.dateOfSale}>{errors.dateOfSale.message}</div>
            )}
          </div>
        </div>
            <AnimalsComponent formProps={formProps} fieldArrayProps={fieldArrayProps} approvalCodeRef={approvalCodeRef} />
        <div className={styles.addCattleRow}>
          <div className={styles.addCattleBtn} disabled={fields.some(f => f.itemState === 'EDIT')} onClick={handleAddCattle}><FontAwesomeIcon icon={faPlusSquare} />Add Cattle</div>
        </div>

        <div className={styles.totalRow}>
          <div className={styles.totalItem}>
            <label>Total purchase price: </label>
            <span className={styles.price}>{totalPurchasePriceRef.current}</span>
          </div>
          <div className={styles.totalItem}>
            <label>Total premium: </label>
            <span className={styles.price}>{totalPremiumRef.current}</span>
          </div>
        </div>


          <div className={styles.acknowledgementRow}>
            <input type="checkbox"
                  id="acknowledgement"
                  onChange={() => toggleAck(!acknowledgedRef.current)}
                  checked={acknowledgedRef.current}
            />
            <p>I understand that by acknowledging this application, each answer given in this application is a statement of fact that becomes a part of the policy. I acknowledge that I am aware that if at any time it is discovered any of the statements contained in this application are concealed or falsely stated, the policy may be modified, rescinded, or declared void from its inception and in accordance with any applicable state law.</p>
          </div>
          <div className={styles.buttonRow}>
            <input disabled={isSubmitting} className={commonStyles.buttonClear} type="button" value="Go Back" onClick={() => navigate(-1)} />
            <button className={commonStyles.buttonFilled} type="submit"
            disabled= {
              !acknowledgedRef.current
              // || animalsRef.current.length === 0
              ||  isSubmitting
            }> {isSubmitting && ( <FontAwesomeIcon icon={faSpinner} spin={true}/>)} Submit Application </button>
          </div>
       </form>
      </div>
    </div>
  );
}

export default Form2;
