import styles from './Form.module.scss';
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import CustomDatePicker from './CustomDatePicker';
import { useCustomEventListener } from 'react-custom-events';
import moment from 'moment';

export default function AnimalForm({
  animal,
  index,
  formProps,
  approvalCode,
  setItemState,
  itemStateRef,
  onDeleteClick
}) {

  const APPROVAL_CODE = approvalCode;
  const FIVE_YEARS_IN_DAYS = 365 * 5;
  const { register, trigger,formState: { errors },setError, clearErrors , setValue, getValues} = formProps;

  const breeds = ["","Angus","Charolais","Chianina","Gelbvieh","Hereford","Limousine","Maine","Red Angus","Shorthorn","Simmental","Wagyu","Commercial","Other"];
  const uses = ["","Only Breeding","Only Show","Show/Breeding"];

  const isUniqueLotNo = (newLotNum) => {
    return !getValues().animals.filter((_, animalIndex) => index !== animalIndex).some(animal => animal.lotno === newLotNum);
  }

  function getDOB(){
    let ret = null;
    if(animal.dob){
      if(animal.dob instanceof Date){
        ret = animal.dob;
      } else {
        ret =  moment(animal.dob, 'MM/DD/YYYY').toDate();
      }
    } else {
      ret = moment().subtract(90, "days").toDate();
    }
    return ret;
  }


  function filterInt(value) {
    if (/^([\d,]+)$/.test(value)) {
      return Number(value.replace(',', ''))
    } else {
      return NaN
    }
  }

  function checkPrice(value) {
    if (value !== null && value.toLowerCase() === APPROVAL_CODE) {
      return true;
    }
    const price = filterInt(value);
    if (!isNaN(price) && (price > 30000)) {
      alert("For any purchase price over $30,000, call Jimmy James at 580-481-0955 to bind coverage.");
    }
    return !isNaN(price) && (price <= 30000);
  }

  function onSave() {
    if (errors.animals && errors.animals.length > index &&  errors.animals[index]){
      console.log(errors.animals[index]);
    } else {
      // animal.itemState = "CLOSED";
      setItemState("CLOSED");
      setValue(`animals[${index}].itemState`,"CLOSED");
    }
  }

  async function validateAndSave() {
    await trigger();
    onSave();
  }

  useCustomEventListener('submit-open-form', () => {
    onSave();
  });


  return (
  <div>
    <fieldset className={styles.light}>

      <div className={styles.fieldRow}>
        <div className={styles.field}>
          <div className={styles.label}>Animal's Name</div>
           <input type="text"  placeholder="Enter name" {...register(`animals[${index}].name` ,
            {
              value: animal.name,
              required: true,
              maxLength: 30
            }
            )}/>

          { errors.animals && errors.animals.length > index && errors.animals[index] && errors.animals[index].name && (
            <div className={styles.error}>Animal name is required</div>
          )}
        </div>

        <div className={styles.field}>
          <div className={styles.label}>Registration No.</div>
          <input type="text" id={`animals[${index}].regno`} placeholder="Enter number" {...register(`animals[${index}].regno`,
            {
              value: animal.regno,
              maxLength: 30
            })}/>
          {errors.animals && errors.animals.length > index && errors.animals[index] &&  errors.animals[index].regno && (
            <div className={styles.error}>Registration number is required</div>
          )}
        </div>
      </div>

      <div className={styles.fieldRow}>
        <div className={styles.twoThirdsField}>
          <div className={styles.label}>Date of Birth</div>
          <CustomDatePicker value={getDOB()} onChange={(dt)=> {
             if (moment().diff(dt, "days") < 90) {
               setError(`animals[${index}].dob`, {message: `Minimum age 90 days`});
             } else if (moment().diff(dt, "days") > FIVE_YEARS_IN_DAYS) {
               setError(`animals[${index}].dob`, {message: `Maximum age 5 years`});
             } else {
              clearErrors(`animals[${index}].dob`);
             }
             setValue(`animals[${index}].dob`,moment(dt).format('MM/DD/YYYY'));
          }} />
          {errors.animals && errors.animals.length > index && errors.animals[index] &&  errors.animals[index].dob && (
            <div className={styles.error}>{ errors.animals[index].dob.message} </div>
          )}
        </div>

        <div className={styles.oneThirdField}>
          <div className={styles.label}>Gender</div>
          <select id={`animals[${index}].gender`} placeholder="Select gender" {...register(`animals[${index}].gender`, {
            value: animal.gender,
            required: {value: true, message: "Gender required"},
            validate: (value) => (value !== null)
          })}>
            <option value=""/>
            <option value="F">Female</option>
            <option value="M">Male</option>
          </select>
          <div className={styles.error}>
            {errors.animals && errors.animals.length > index && errors.animals[index] &&  errors.animals[index].gender && "Gender is required"}
          </div>
        </div>
      </div>

      <div className={styles.fieldRow}>
        <div className={styles.field}>
          <div className={styles.label}>Breed</div>
          <select id={`animals[${index}].breed`} {...register(`animals[${index}].breed`,
            {
              value: animal.breed,
              required: true
            })} >
              {breeds.map(breed => <option key={breed} value={breed} >{breed}</option>) }
            </select>
          {errors.animals && errors.animals.length > index && errors.animals[index] &&  errors.animals[index].breed && (
            <div className={styles.error}>Breed is required</div>
          )}
        </div>

        <div className={styles.field}>
          <div className={styles.label}>Use</div>
          <select id={`animals[${index}].use`} {...register(`animals[${index}].use`,
            {
              value: animal.use,
              required: true
            })} >
            {uses.map(use => <option key={use} value={use} >{use}</option>) }
          </select>
          {errors.animals && errors.animals.length > index && errors.animals[index] &&  errors.animals[index].use && (
            <div className={styles.error}>Use is required</div>
          )}
        </div>

        <div className={styles.quarterField}>
          <div className={styles.label}>Lot No.</div>
          <input type="text" id={`animals[${index}].lotno`} placeholder="Enter lot #" {...register(`animals[${index}].lotno`,
            {
              value: animal.lotno,
              required: true,
              maxLength: 30,
              validate: isUniqueLotNo
            })}/>
          {errors.animals && errors.animals.length > index && errors.animals[index] &&  errors.animals[index].lotno && (
            <div className={styles.error}>{errors.animals[index].lotno.type === "validate"? "Lot number should be unique" :"Lot number is required"}</div>
          )}
        </div>
      </div>

      <div className={styles.fieldRow}>
        <div className={styles.field}>
          <div className={styles.label}>Purchase Price</div>
          <div className={styles.purchasePrice}>
            <span className={styles.symbol}>$</span>
            <input id={`animals[${index}].purchaseprice`} type="text" maxLength="10" placeholder="Enter price"
                  {...register(`animals[${index}].purchaseprice`,
            {
              value: animal.purchaseprice,
              required: {value: true, message: "Purchase price required"},
              validate: (val) => checkPrice(val)
            })}/>
          </div>
            { errors.animals && errors.animals.length > index && errors.animals[index] &&  errors.animals[index].purchaseprice && (
            <div className={styles.error}>{errors.animals[index].purchaseprice.type === "validate"? "Invalid price": errors.animals[index].purchaseprice.message}</div>
            )}
        </div>

        <div className={`${styles.buttons} ${styles.openFormButtons}`}>
          { ["EDIT"].includes(itemStateRef.current) && <button type="button" className={styles.editButton} onClick={validateAndSave}><FontAwesomeIcon icon={faEdit}/> Update</button> }
          { ["NEW","EDIT"].includes(itemStateRef.current) &&  <button type="button" className={styles.editButton} onClick={onDeleteClick}><FontAwesomeIcon icon={faTrash}/> Delete</button> }
        </div>
      </div>
    </fieldset>

  </div>
  );
}
